import JB from '../../scripts/JB';

const disclaimer = {
  form: null,
  init() {
    disclaimer.dom();
    if (!disclaimer.form) {
      return;
    }

    disclaimer.bindEvents();
  },
  dom() {
    disclaimer.form = document.querySelector('.o-disclaimer form');
    if (!disclaimer.form) {
      return;
    }
    disclaimer.agreeButton = disclaimer.form.querySelector('jb-button[name="agree"]');
    disclaimer.disagreeButton = disclaimer.form.querySelector('jb-button[name="disagree"]');
  },
  bindEvents() {
    if (!disclaimer.form) {
      return;
    }

    disclaimer.form.addEventListener('submit', (submitEvent) => {
      const valid = JB.validate(disclaimer.form);
      if (!valid) {
        submitEvent.preventDefault();
        return;
      }
      const { disclaimerId } = disclaimer.form.dataset;
      if (!disclaimerId) {
        return;
      }

      JB.setCookie(`disclaimer_${disclaimerId}`, 1);
    });

    disclaimer.agreeButton.addEventListener('click', (clickEvent) => {
      clickEvent.preventDefault();
      disclaimer.form.requestSubmit();
    });

    disclaimer.disagreeButton.addEventListener('click', (clickEvent) => {
      clickEvent.preventDefault();
      window.location = '/';
    });
  },
};

export default disclaimer;
