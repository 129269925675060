const JB = {
  validate(form) {
    const inputs = form.querySelectorAll('.input:not(.optional), jb-checkbox');
    let valid = true;
    inputs.forEach((item) => {
      if (item.classList.contains('radio')) {
        if (!JB.validateRadioBtn(item)) {
          valid = false;
        }
      } else if (item.classList.contains('date-picker-wrapper')) {
        if (!JB.validateDate(item)) {
          valid = false;
        }
      } else if (item.classList.contains('checkbox')) {
        if (!JB.validateCheckbox(item)) {
          valid = false;
        }
      } else if (item.classList.contains('upload')) {
        if (!JB.validateUpload(item)) {
          valid = false;
        }
      } else if (item.classList.contains('textarea')) {
        if (!JB.validateTextArea(item)) {
          valid = false;
        }
      } else if (item.classList.contains('select')) {
        if (!JB.validateSelectList(item)) {
          valid = false;
        }
      } else if (item.tagName === 'JB-CHECKBOX') {
        valid = item.validate();
      } else if (!JB.validateTextFields(item)) {
        valid = false;
      }
      if (item.querySelector('input[type="email"]') !== null) {
        if (!JB.validateEmailTextField(item)) {
          valid = false;
        }
      }
    });
    return valid;
  },

  showErrorMsg(item, errorItem) {
    item.classList.add('error');

    if (errorItem) {
      // eslint-disable-next-line no-param-reassign
      errorItem.style.display = 'block';
    }
  },

  hideErrorMsg(item, errorItem) {
    item.classList.remove('error');

    if (errorItem) {
      // eslint-disable-next-line no-param-reassign
      errorItem.style.display = 'none';
    }
  },

  isValid(item, errorItem, errorCondition) {
    const backendError = item.querySelector('[data-force-initial-display]');
    if (backendError) {
      item.classList.add('error');
      return false;
    }
    if (errorCondition) {
      JB.showErrorMsg(item, errorItem);
      return false;
    }
    JB.hideErrorMsg(item, errorItem);
    return true;
  },

  getErrorElementByItemAndCode(item, code) {
    return item.querySelector('[data-error-code="' + code + '"]');
  },

  validateRadioBtn(item) {
    const radioBtn = item.querySelector('input');
    const radioBtnLabel = item.querySelector('label');
    const { name } = radioBtn;
    const title = item.parentNode.querySelector('p');

    if (document.querySelectorAll('input[name="' + name + '"]:checked').length === 0) {
      radioBtnLabel.classList.add('error');
      title.classList.add('error-title');
      return false;
    }
    radioBtnLabel.classList.remove('error');
    title.classList.remove('error-title');
    return true;
  },

  validateCheckbox(item) {
    const checkbox = item.querySelector('input');
    const { name } = checkbox;
    const errorMsg = JB.getErrorElementByItemAndCode(item, 'field-required');
    const condition = document.querySelectorAll('input[name="' + name + '"]:checked').length === 0;
    return JB.isValid(item, errorMsg, condition);
  },

  validateUpload(item) {
    const uploadFileName = item.querySelector('.upload__file-name');
    const errorMsg = JB.getErrorElementByItemAndCode(item, 'field-required');
    const condition = uploadFileName.textContent !== 'No file selected';
    return JB.isValid(item, errorMsg, condition);
  },

  validateSelectList(item) {
    const selectList = item.querySelectorAll('option');
    const errorMsg = JB.getErrorElementByItemAndCode(item, 'field-required');
    const backendError = item.querySelector('[data-force-initial-display]');
    if (backendError) {
      item.classList.add('error');
      return false;
    }
    const condition = selectList[0].selected;
    return JB.isValid(item, errorMsg, condition);
  },

  validateTextArea(item) {
    const textarea = item.querySelector('textarea');
    const errorMsg = JB.getErrorElementByItemAndCode(item, 'field-required');
    const condition = textarea && textarea.value === '';
    return JB.isValid(item, errorMsg, condition);
  },

  validateEmailTextField(item) {
    const emailAddress = item.querySelector('input').value;
    const isEmailValid = JB.validateEmail(emailAddress);
    const errorMsg = JB.getErrorElementByItemAndCode(item, 'invalid-email');
    const condition = !isEmailValid;
    return JB.isValid(item, errorMsg, condition);
  },

  validateDate(item) {
    const dateField = item.querySelector('input[type="text"]');
    const errorMsg = JB.getErrorElementByItemAndCode(item, 'field-required');
    const condition = dateField && dateField.value === '';
    return JB.isValid(item, errorMsg, condition);
  },

  validateTextFields(item) {
    const textField = item.querySelector('input[type="text"]');
    const errorMsg = JB.getErrorElementByItemAndCode(item, 'field-required');
    const condition = textField && textField.value === '';
    return JB.isValid(item, errorMsg, condition);
  },

  validateEmail(email) {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return re.test(String(email).toLowerCase());
  },

  getXMLHttpRequest(url, callbackFunction) {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200 && callbackFunction !== undefined) {
        callbackFunction(JSON.parse(xhttp.responseText));
      }
    };

    xhttp.open('GET', url, true);
    xhttp.send();
  },

  /* Helper debounce function */
  debounce(callback, wait, immediate, ...args) {
    let timeout;
    return function () {
      const context = this;
      const later = function () {
        timeout = null;
        if (!immediate) {
          callback.apply(context, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait || 200);

      if (callNow) {
        callback.apply(context, args);
      }
    };
  },

  removeElementCssClassByPrefix(el, cssClassPrefix) {
    if (el && cssClassPrefix && cssClassPrefix.length > 0) {
      for (let i = 0; i < el.classList.length; i += 1) {
        const className = el.classList[i];
        if (className.startsWith(cssClassPrefix)) {
          el.classList.remove(className);
        }
      }
    }
  },

  elementIsInCenterOfViewport(el) {
    const bounding = el.getBoundingClientRect();
    const viewportCenter = (window.innerHeight / 2);

    return viewportCenter >= bounding.top && viewportCenter <= bounding.bottom;
  },

  elementIsLessThenBottomOfViewport(el) {
    const bounding = el.getBoundingClientRect();
    const viewportBottom = (window.innerHeight);

    return viewportBottom >= bounding.bottom;
  },

  getCookie(name) {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  },

  setCookie(name, value, days, action) {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = name + '=' + value + ';path=/;expires=' + d.toUTCString();
    if (action === 'delete') {
      document.cookie = name + '=' + value + ';path=/;expires=' + d.toUTCString() + ';domain=' + this.getWildcardDomain();
    }
  },

  getCurrentDomain() {
    return window.location.hostname;
  },

  getWildcardDomain() {
    return this.getCurrentDomain().substring(this.getCurrentDomain().indexOf('.'));
  },

  getI18nCookieData() {
    const cookieValue = this.getCookie('i18n') || '||0||';
    const parts = cookieValue.split('|');
    return {
      region: parts[0],
      country: parts[1],
      language: parts[2],
      languageIso2: parts[3],
      market: parts[4],
    };
  },

  dispatchEvent(name, obj) {
    const evt = new CustomEvent(name, obj);
    document.body.dispatchEvent(evt);
  },
};

export default JB;
