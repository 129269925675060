import JB from '../../scripts/JB';

const languageBadge = {
  badges: null,
  init() {
    // todo: when migrating to web components: if the jb-language alert is shown, badges must not be shown
    languageBadge.badges = document.querySelectorAll('[data-language-badge]');
    languageBadge.badges.forEach((badge) => {
      badge.classList.removeModifier('hidden');
      const badgeText = badge.querySelector('p');
      const localizedText = badgeText.dataset['localizedLanguageBadge-' + JB.getI18nCookieData().language];
      if (localizedText) {
        badgeText.innerHTML = localizedText;
      }
    });
  },
};

export default languageBadge;
